import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const FirstTimePage = () => {
    return (
        <Layout>
            <Seo title="Pierwsza Wizyta"/>
            <section className='firsttime-container'>
                <section className='firsttime-hero'>
                    <div className='firsttime-hero_text'>
                        <h1 className='firsttime-hero_title'>Pierwsza wizyta?</h1>
                        <p className='firsttime-hero_description'>
                        Zapisy i płatności za zajęcia przyjmujemy wyłącznie poprzez zewnętrzny system rezerwacyjny Fitssey 
                        (platforma internetowa i/lub aplikacja na telefon).<br/><br/>
                        1. Najpierw w kilka sekund założysz swój profil korzystając z poniższego linku, wpiszesz w formularz 
                        rejestracyjny dosłownie swoje podstawowe dane. Tym samym założysz swój profil w naszym Studio.<br/><br/>
                        2. Tuż po rejestracji możesz od razu zarezerwować dla siebie zajęcia, czyli najpierw z cennika wybrać odpowiednią
                         usługę: np. „Konsultacja diagnostyczna z analizą postawy ciała”, „Trening indywidualny - wejście jednorazowe” lub wybrać któryś z naszych Pakietów promocyjnych.<br/><br/>
                        3. W przypadku chęci zapisu na zajęcia indywidualne lub w duetach skontaktuj się z nami - najpierw ustalimy Twoje potrzeby, poradzimy, który z instruktorów ma najlepszą dla Ciebie specjalizację oraz znajdziemy u niego wygodny dla Ciebie termin na zajęcia regularne.
                        <br /><br />
                        4. Później w kalendarzu będziesz mógł już wyszukać ustalony między nami dla Ciebie termin, czyli np. z danego tygodnia  wybierasz dzień i godzinę, którą dla Ciebie tam wpisaliśmy. Następnie kliknij w dany termin przyciskiem „Zapisz się”.<br/><br/>
                        UWAGA! Jeśli chcesz umówić się na diagnostykę, nie zapomnij odesłać nam mailem wypełnionego kwestionariusza diagnostycznego przed pierwszym spotkaniem.<br/><br/>
                        Zapisując się przez system każdy zarządza swoim profilem. 
                        Zapisy przyjmujemy na co najmniej 24 godziny przed zajęciami. Anulacji i przesunięć terminu też 
                        dokonujemy na co najmniej 24 godziny przed zajęciami. Inaczej system blokuje dany zapis na ten właśnie moment.<br/><br/>
                        Jeśli potrzebujesz dodatkowych wyjaśnień, bądź z nami w kontakcie!
                        </p>
                    </div>
                    <div className='firsttime-signup'>
                        <a href='/documents/Regulamin.pdf' download="Regulamin_Pilates Terapeutyczny Studio PL/ENG" className='firsttime-signup_download-document'>POBIERZ REGULAMIN &#8628;</a>
                        <a href='/documents/Kwestionariusz_diagnostyczny.docx' download="Kwestionariusz do konsultacji diagnostycznej" className='firsttime-signup_download-document'>POBIERZ KWESTIONARIUSZ HISTORII ZDROWIA &#8628;</a>
                        <a href="mailto:pilates.terapeutyczny@gmail.com.com" className='firsttime-signup_email'>pilates.terapeutyczny@gmail.com</a>
                        <div className='firsttime-signup_link-container'>
                            <a href="https://app.fitssey.com/pilatesterapeutyczny/frontoffice" target="_blank" className='firsttime-signup-link_link'>ZAPISZ SIĘ</a>
                        </div>
                    </div>
                </section>
            </section>
        </Layout>
    )
}

export default FirstTimePage;